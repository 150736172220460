import { takeEvery, put, call } from "redux-saga/effects";
import { RegistrationRequest, registrationActions } from "./actions";
import { Action } from "typescript-fsa";

import { RegistrationService } from "../../services/registrationService/index";
import { RegistrationResponsePayload } from "../../services/registrationService/RegistrationService.type";

function* RegistrationWorker(action: Action<RegistrationRequest>) {
  const { payload } = action;
  const { data, redirect } = payload;

  try {
    const response: RegistrationResponsePayload = yield call(
      RegistrationService.register,
      data
    );
    redirect();
    yield put(
      registrationActions.register.done({ params: payload, result: response })
    );
  } catch (error) {
    yield put(registrationActions.register.failed(error.message));
  }
}

function* RegistrationWatcher(): IterableIterator<any> {
  yield takeEvery(registrationActions.register.started, RegistrationWorker);
}

export default RegistrationWatcher;
