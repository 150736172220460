import actionCreatorFactory from "typescript-fsa";
import {
  UploadBillRequest,
  UploadBillResponse
} from "../../services/billService/BillService.type";

const actionCreator = actionCreatorFactory();

const UploadBillActions = actionCreator.async<
  UploadBillRequest,
  UploadBillResponse,
  Error
>("UPLOAD_BILL");

export const billActions = {
  upload: UploadBillActions
};
