import eligibilityService from "./EligibilityService";

export const EligibilityService = {
  initializeLoanApplication: eligibilityService.initializeLoanApplication,
  eligibilityMetaData: eligibilityService.getEligibilityMetaData,
  eligibilityDetails: eligibilityService.eligibilityDetails,
  saveEligibilityDetails: eligibilityService.saveEligibilityDetails,
  getIndustries: eligibilityService.getIndustries,
  getSubIndustries: eligibilityService.getSubIndustries,
  getCustomerList: eligibilityService.getCustomerList,
  wepGenerateOtp: eligibilityService.generateOTP,
  wepVerifyOtp: eligibilityService.verifyOTP,
  updateLoanStatus: eligibilityService.updateStatus,
  triggerManualFileVerification:
    eligibilityService.triggerManualFileVerification,
  rejectionReasons: eligibilityService.eligibilityAmountRejectionReasons,
  acceptRejectUpdatedEligibilityAmount:
    eligibilityService.acceptRejectUpdatedEligibilityAmount,
  uploadGstrConfig: eligibilityService.uploadGstrConfig,
  deleteGstrConfig: eligibilityService.deleteGstrConfig,
  uploadDisbursalAccountProof: eligibilityService.uploadDisbursalAccountProof,
  startLoanCalculation: eligibilityService.startLoanCalculation,
  fetchLoanCalculation:eligibilityService.fetchLoanCalculation
};
