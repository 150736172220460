import { reducerWithInitialState } from "typescript-fsa-reducers";
import { authActions } from "./actions";
import { LoginState } from "./state";

export const initialState: LoginState = {
  eligibilityMetaData: {
    gstin: "",
    eligibilityId: "",
    legalName: "",
    primaryAddress: "",
    isGstinInactive: false,
    loading: false,
    status: null,
    subStatus: {
      kycStatus: null,
      disbursalBankAccountStatus: null,
      receivablesStatus: null
    },
    eligibilityAmount: 0,
    amountUtilized: null
  },
  loading: false,
  loginIssue: {
    message: "",
    phoneNumber: "",
    fromLogin: true,
    loading: false,
    error: null
  }
};

export const AuthReducer = reducerWithInitialState(initialState)
  .case(authActions.login.started, state => ({
    ...state,
    loading: true
  }))
  .case(authActions.login.done, (state, reponse) => ({
    ...state,
    loggedIn: true,
    selectedGstin: reponse.result.gstin,
    loading: false,
    error: undefined
  }))
  .case(authActions.login.failed, (state, errorMessage) => ({
    ...state,
    loading: false,
    loggedIn: false,
    error: errorMessage
  }))
  .case(authActions.getEligibilityMetaData.started, state => ({
    ...state,
    eligibilityMetaData: { ...state.eligibilityMetaData, loading: true }
  }))
  .case(authActions.getEligibilityMetaData.failed, (state, errorMessage) => ({
    ...state,
    eligibilityMetaData: {
      ...state.eligibilityMetaData,
      loading: true,
      error: errorMessage.error
    }
  }))
  .case(authActions.getEligibilityMetaData.done, (state, response) => ({
    ...state,
    eligibilityMetaData: { ...response.result, loading: false }
  }))
  .case(authActions.setIssuePhoneNumber, (state, payload) => ({
    ...state,
    loginIssue: {
      ...state.loginIssue,
      error: null,
      phoneNumber: payload.phoneNumber,
      fromLogin: payload.fromLogin
    }
  }))
  .case(authActions.reportLoginIssue.started, state => ({
    ...state,
    loginIssue: {
      ...state.loginIssue,
      error: null,
      loading: true
    }
  }))
  .case(authActions.reportLoginIssue.done, (state, response) => ({
    ...state,
    loginIssue: {
      ...state.loginIssue,
      loading: false,
      error: null,
      message: response.result?.message || ""
    },
    loading: false,
    error: undefined
  }))
  .case(authActions.reportLoginIssue.failed, (state, errorMessage) => ({
    ...state,
    loginIssue: {
      ...state.loginIssue,
      loading: false,
      error: errorMessage
    },
    loading: false,
    error: errorMessage
  }))
  .case(authActions.updateStatus, (state, payload) => ({
    ...state,
    eligibilityMetaData: {
      ...state.eligibilityMetaData,
      status: payload.status || state.eligibilityMetaData?.status,
      subStatus: {
        ...state.eligibilityMetaData?.subStatus,
        ...payload.subStatus
      }
    }
  }))
  .case(authActions.updateGstinStatus, (state, payload) => ({
    ...state,
    eligibilityMetaData: {
      ...state.eligibilityMetaData,
      isGstinInactive: payload
    }
  }));
