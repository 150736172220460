import { AppSettings, LogLevel } from "../AppSettings";

/**
 * Consist of methods that log error messages, trace, etc on the console.
 */

export class Logging {
  public static error(
    message?: string | undefined | null,
    ...optionalParams: any[]
  ): boolean {
    if (AppSettings.logging.logLevel >= LogLevel.Errors) {
      if (message) {
        if (optionalParams && optionalParams.length > 0) {
          Logging.consoleGroup(message, optionalParams);
        } else {
          console.error(message);
        }
      }
      return true;
    } else {
      return false;
    }
  }

  public static consoleGroup(
    message: string | undefined | null,
    optionalParams: any[]
  ) {
    console.groupCollapsed(message);
    optionalParams.forEach(param => console.log(param));
    console.groupEnd();
  }

  public static debug(
    message?: string | undefined | null,
    ...optionalParams: any[]
  ): boolean {
    if (AppSettings.logging.logLevel >= LogLevel.Debug) {
      if (message) {
        if (optionalParams && optionalParams.length > 0) {
          Logging.consoleGroup(message, optionalParams);
        } else {
          console.log(message);
        }
      }
      return true;
    } else {
      return false;
    }
  }

  public static trace(
    message?: string | undefined | null,
    ...optionalParams: any[]
  ): boolean {
    if (AppSettings.logging.logLevel >= LogLevel.Trace) {
      if (message) {
        if (optionalParams && optionalParams.length > 0) {
          Logging.consoleGroup(message, optionalParams);
        } else {
          console.log(message);
        }
      }
      return true;
    } else {
      return false;
    }
  }

  public static traceData<T>(data: T): T | undefined {
    return AppSettings.logging.logLevel >= LogLevel.Trace ? data : undefined;
  }
}
