import React, { Suspense } from "react";
import "./App.css";
import { Global } from "@emotion/core";
import { theme } from "./theme/Theme";

const RoutesContainer = React.lazy(() => import("./RoutesContainer"));

const App: React.FC = () => {
  return (
    <div className="App">
      <Global styles={theme} />
      <Suspense fallback={null}>
        <RoutesContainer />
      </Suspense>
    </div>
  );
};

export default App;
