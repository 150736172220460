import ApiServiceBase from "./ApiServiceBase";
import { ServiceType } from "./ServiceType.data";
import { Logging } from "../helper/Logging";
import { HttpContentType } from "./HttpContentType";
import { QueryPath } from "./QueryPath.data";

export default class ApiService extends ApiServiceBase {
  protected readonly serviceType: ServiceType;
  protected readonly hideFeedback: boolean;

  public constructor(serviceType: ServiceType, hideFeedback?: boolean) {
    super(serviceType);
    this.serviceType = serviceType;
    this.hideFeedback = !!hideFeedback;
  }

  public get<T = void>(path: QueryPath, isBlob?: boolean): Promise<T> {
    const config = this.getConfig(HttpContentType.Json);
    Logging.debug(`ApiService.get ${this.getUrl(path)}`, { path, config });
    const instance = this.getAxiosInstance();
    return new Promise<T>((resolve, reject) =>
      instance
        .get(
          this.getUrl(path),
          isBlob ? { ...config, responseType: HttpContentType.Blob } : config
        )
        .then(
          (res: any) => {
            Logging.trace(`ApiService.get ${this.getUrl(path)} @Result`, {
              path,
              result: res
            });
            resolve(res.data.data ? res.data.data : res.data);
          },
          (err: any) => {
            Logging.error(`ApiService.get ${this.getUrl(path)} @Error`, {
              path,
              error: err
            });
            reject(this.processError(err));
          }
        )
    );
  }

  public post<T = void>(path: QueryPath, body: any): Promise<T> {
    const config = this.getConfig(HttpContentType.Json);

    Logging.debug(`ApiService.post ${this.getUrl(path)}`, {
      path,
      config,
      body,
      bodyJson: JSON.stringify(body)
    });
    const instance = this.getAxiosInstance();

    return new Promise<T>((resolve, reject) =>
      instance
        .post(this.getUrl(path), body, config) //Adding headers
        .then(
          (res: any) => {
            Logging.trace(`ApiService.post ${this.getUrl(path)} @Result`, {
              path,
              body,
              bodyJson: JSON.stringify(body),
              result: res
            });
            resolve(res.data.data ? res.data.data : res.data);
          },
          (err: any) => {
            Logging.error(`ApiService.post ${this.getUrl(path)} @Error`, {
              path,
              body,
              bodyJson: JSON.stringify(body),
              error: err
            });
            reject(this.processError(err));
          }
        )
    );
  }

  public patch<T = void>(path: QueryPath, body: any): Promise<T> {
    const config = this.getConfig(HttpContentType.Json);

    Logging.debug(`ApiService.patch ${this.getUrl(path)}`, {
      path,
      config,
      body,
      bodyJson: JSON.stringify(body)
    });
    const instance = this.getAxiosInstance();

    return new Promise<T>((resolve, reject) =>
      instance
        .patch(this.getUrl(path), body, config) //Adding headers
        .then(
          (res: any) => {
            Logging.trace(`ApiService.patch ${this.getUrl(path)} @Result`, {
              path,
              body,
              bodyJson: JSON.stringify(body),
              result: res
            });
            resolve(res.data.data ? res.data.data : res.data);
          },
          (err: any) => {
            Logging.error(`ApiService.patch ${this.getUrl(path)} @Error`, {
              path,
              body,
              bodyJson: JSON.stringify(body),
              error: err
            });
            reject(this.processError(err));
          }
        )
    );
  }

  public put<T = void>(path: QueryPath, body?: any): Promise<T> {
    const config = this.getConfig(HttpContentType.Json);

    Logging.debug(`ApiService.put ${this.getUrl(path)}`, {
      path,
      config,
      body,
      bodyJson: JSON.stringify(body)
    });
    const instance = this.getAxiosInstance();

    return new Promise<T>((resolve, reject) =>
      instance.put(this.getUrl(path), body, config).then(
        (res: any) => {
          Logging.trace(`ApiService.put ${this.getUrl(path)} @Result`, {
            path,
            body,
            bodyJson: JSON.stringify(body),
            result: res
          });
          resolve(res.data.data ? res.data.data : res.data);
        },
        (err: any) => {
          Logging.error(`ApiService.put ${this.getUrl(path)} @Error`, {
            path,
            body,
            bodyJson: JSON.stringify(body),
            error: err
          });
          reject(this.processError(err));
        }
      )
    );
  }

  public delete<T = void>(path: QueryPath): Promise<T> {
    const config = this.getConfig(HttpContentType.Json);
    Logging.debug(`ApiService.delete ${this.getUrl(path)}`, { path, config });
    const instance = this.getAxiosInstance();
    return new Promise<T>((resolve, reject) =>
      instance.delete(this.getUrl(path), config).then(
        (res: any) => {
          Logging.trace(`ApiService.delete ${this.getUrl(path)} @Result`, {
            path,
            result: res
          });
          resolve(res.data.data ? res.data.data : res.data);
        },
        (err: any) => {
          Logging.error(`ApiService.delete ${this.getUrl(path)} @Error`, {
            path,
            error: err
          });
          reject(this.processError(err));
        }
      )
    );
  }

  public postMultipart<T = void>(path: QueryPath, data: any): Promise<T> {
    const config = this.getConfig(HttpContentType.MultipartFormData);

    Logging.debug(`ApiService.postMultipart ${this.getUrl(path)}`, {
      path,
      config,
      data
    });
    const instance = this.getAxiosInstance();

    // Creating a Form Data
    const formData = new FormData();
    //adding all the values obtained to form data
    Object.keys(data).map(item => formData.append(item, data[item]));

    return new Promise<T>((resolve, reject) => {
      return instance.post(this.getUrl(path), formData, config).then(
        res => {
          Logging.trace(
            `ApiService.postMultipart ${this.getUrl(path)} @Result`,
            { path, formData, result: res }
          );
          resolve(res.data);
        },
        err => {
          Logging.error(
            `ApiService.postMultipart ${this.getUrl(path)} @Error`,
            { path, formData, error: err }
          );
          reject(this.processError(err));
        }
      );
    });
  }
}
