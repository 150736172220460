import { takeEvery, put, call } from "redux-saga/effects";
import { billActions } from "./actions";
import { Action } from "typescript-fsa";

import { BillService } from "../../services/billService/index";
import {
  UploadBillRequest,
  UploadBillResponse
} from "../../services/billService/BillService.type";

function* UploadBillWorker(action: Action<UploadBillRequest>) {
  const {
    payload,
    payload: { data, redirect }
  } = action;
  try {
    const response: UploadBillResponse = yield call(
      BillService.uploadBill,
      data
    );
    if (redirect) {
      redirect();
    }
    yield put(billActions.upload.done({ params: payload, result: response }));
  } catch (error) {
    yield put(billActions.upload.failed(error.message));
  }
}

function* BillWatcher(): IterableIterator<any> {
  yield takeEvery(billActions.upload.started, UploadBillWorker);
}

export default BillWatcher;
