class SessionStorage {
  private readonly sessionstorage = window.sessionStorage;

  public setItem(key: string, item: string) {
    this.sessionstorage.setItem(key, item);
  }

  public getItem(key: string) {
    return this.sessionstorage.getItem(key);
  }

  public removeItem(key: string) {
    this.sessionstorage.removeItem(key);
  }

  public clearAll() {
    this.sessionstorage.clear();
  }
}

const sessionStorage = new SessionStorage();
export default sessionStorage;
