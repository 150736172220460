import { ApplicationState } from "./RootReducers";

import { initialState as eligibilityState } from "./eligibility/reducer";
import { initialState as billState } from "./bill/reducer";
import { initialState as loginState } from "./auth/reducer";
import { initialState as profileState } from "./profile/reducer";
import { initialState as registrationState } from "./registration/reducer";

export const initialApplicationState: ApplicationState = {
  eligibilityState,
  billState,
  loginState,
  profileState,
  registrationState
};
