import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store/Store";
import { Modal } from "antd";

const handleConfirmation: (
  message: string,
  callback: (ok: boolean) => void
) => void = (message, callback) => {
  Modal.confirm({
    content: message,
    onOk: () => callback(true),
    onCancel: () => callback(false)
  });
};

// Custom event dispatched on setting session Storage. Used for listen to changes
const originalSetItem = window.sessionStorage.setItem;
const originalClearAll = window.sessionStorage.clear;
const originalRemoveItem = window.sessionStorage.removeItem;

window.sessionStorage.setItem = function() {
  const event = new Event("sessionStorageModified");
  originalSetItem.apply(this, arguments as any);
  window.dispatchEvent(event);
};

window.sessionStorage.clear = function() {
  const event = new Event("sessionStorageModified");
  originalClearAll.apply(this, arguments as any);
  window.dispatchEvent(event);
};

window.sessionStorage.removeItem = function() {
  const event = new Event("sessionStorageModified");
  originalRemoveItem.apply(this, arguments as any);
  window.dispatchEvent(event);
};

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter getUserConfirmation={handleConfirmation}>
      <App />
    </BrowserRouter>
  </Provider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
