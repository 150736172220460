import { takeEvery, put, call } from "redux-saga/effects";
import { Action } from "typescript-fsa";

import { profileActions } from "./actions";

import { ProfileService } from "../../services/profileService/index";
import {
  UpdateProfileRequest,
  UpdateProfileResponse,
  UserConsentObject
} from "../../services/profileService/ProfileService.type";
import SessionStorageKeys from "constants/SessionStorageConstants";
import { message } from "antd";

import { ProfileContents as CONSTANTS } from "../../pages/profile/ProfileConstants";
import { applicationActions } from "../../store/application/actions";
import sessionStorage from "../../services/SessionStorage";

function* SetBorrowerConsent(action:Action<UserConsentObject>){
  const {payload} = action;
  try{
    const response:any = yield ProfileService.setBorrowerConsent(payload) as any;
    yield put(profileActions.setBorrowerConsent.done(response));
  }catch(error){
    yield put(profileActions.setBorrowerConsent.failed(error.message))
  }
}

function* UpdateProfileWorker(action: Action<UpdateProfileRequest>) {
  const {
    payload,
    payload: { data, onSuccess, changeActiveGstin, redirect }
  } = action;
  try {
    const response: UpdateProfileResponse = yield call(
      ProfileService.updateProfile,
      data
    );
    const { token, data: responseData } = response;
    if (token) {
      sessionStorage.setItem(SessionStorageKeys.AuthToken, token);
    }
    if (responseData?.profileDetails.s3Key) {
      sessionStorage.setItem(
        SessionStorageKeys.ProfileImageKey,
        responseData.profileDetails.s3Key
      );
    }

    if (changeActiveGstin) {
      yield put(applicationActions.flushData());
      redirect(routes => routes.home.new);
      sessionStorage.setItem(SessionStorageKeys.GSTIN, data.activeGstNumber);
      message.success(CONSTANTS.feedbacks.profileSwitchSuccess);
    } else {
      if (onSuccess) {
        onSuccess();
      }
      message.success(CONSTANTS.feedbacks.profileUpdateSuccess);
      yield put(
        profileActions.updateProfile.done({ params: payload, result: response })
      );
    }
  } catch (error) {
    yield put(profileActions.updateProfile.failed(error.message));
  }
}

function* ProfileWatcher(): IterableIterator<any> {
  yield takeEvery(profileActions.updateProfile.started, UpdateProfileWorker);
  yield takeEvery(profileActions.setBorrowerConsent.started, SetBorrowerConsent);
}

export default ProfileWatcher;
