import ApiServiceBase from "../ApiServiceBase";
import ApiService from "../ApiService";
import { ServiceType } from "../ServiceType.data";
import { UploadBillPayload, GetBillListResponse } from "./BillService.type";
import { QueryPath } from "../../services/QueryPath.data";

class BillService {
  public uploadBill(data: UploadBillPayload) {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.postMultipart({ route: ["upload-challan"] }, data);
  }
  public getBillList(
    data: QueryPath
  ): GetBillListResponse | Promise<GetBillListResponse> {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.get({
      route: ["challan-details"],
      query: data.query
    });
  }
}

const billService = new BillService();
export default billService;
