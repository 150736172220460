import { combineReducers } from "redux";
import { AnyAction } from "typescript-fsa";

// Reducers
import { EligibilityReducer } from "./eligibility/reducer";
import { UploadBillReducer } from "./bill/reducer";
import { AuthReducer } from "./auth/reducer";
import { RegistrationReducer } from "./registration/reducer";
import { ProfileReducer } from "./profile/reducer";

// State
import { EligibilityState } from "./eligibility/state";
import { BillState } from "./bill/state";
import { LoginState } from "./auth/state";
import { RegistrationState } from "./registration/state";
import { ProfileState } from "./profile/state";
import { initialApplicationState } from "./InitialState";
import { applicationActions } from "./application/actions";

export interface ApplicationState {
  loginState: LoginState;
  registrationState: RegistrationState;
  eligibilityState: EligibilityState;
  billState: BillState;
  profileState: ProfileState;
}

const rootReducer = combineReducers<ApplicationState>({
  eligibilityState: EligibilityReducer,
  billState: UploadBillReducer,
  loginState: AuthReducer,
  registrationState: RegistrationReducer,
  profileState: ProfileReducer
});

export default (state: ApplicationState | undefined, action: AnyAction) => {
  return action.type === applicationActions.flushData.type
    ? rootReducer(initialApplicationState, action)
    : rootReducer(state, action);
};
