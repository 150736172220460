import {
  ReceivableStatus,
  EligibilityStatus,
  SubStatus
} from "../../services/authService/AuthService.type";

export interface InitializeEligibilityIdResponse {
  eligibilityId: string;
}

export interface GetStartedEligibilityRequest {
  redirect: () => void;
}

export interface GetStartedLoanCalculation {
  gstin: string;
  gst_username: string;
  eligibility_id: string;
}

// export interface LoanCalculationResponse{
//   id: number,
//   total_gst_nongst_purchases_gstr3b: number,
//   total_gst_paid_gstr3b:number;
//   total_income_gstr1:number;
//   total_reciepts_gstr1:number;
//   total_late_fees_paid_gstr3b:number;
//   total_number_of_invoices_gstr2a:number;
//   total_purchases_gstr2a:number;
//   pending_gstr1:number;
//   pending_gstr3b:number;
//   eligibility_id: string
// }

export enum LoanCalculationStatus {
  IN_PROGRESS = "IN-PROGRESS",
  COMPLETED = "COMPLETED",
  STARTED = "STARTED",
  FAILED = "FAILED"
}

export interface LoanCalculationResponse {
  status: LoanCalculationStatus;
  gstr_tax: {
    total_gst_nongst_purchases_gstr3b: number;
    total_gst_paid_gstr3b: number;
    total_income_gstr1: number;
    total_reciepts_gstr1: number;
    total_late_fees_paid_gstr3b: number;
    total_number_of_invoices_gstr2a: number;
    total_purchases_gstr2a: number;
    pending_gstr1: number;
    pending_gstr3b: number;
    eligibility_amount: number;
    eligibility_id: string;
  };
}

export enum LoanCalculationType {
  manual = "manual",
  instant = "instant"
}

export enum GstrDocumentType {
  GSTR1 = "GSTR1",
  GSTR2A = "GSTR2A",
  GSTR3B = "GSTR3B"
}

export interface CompanyDetails {
  companyType: string | null;
  unitAddress: string | null;
  unitAddressType: string | null;
  employeesCount: number | null;

  state: string | null;
  pincode: string | null;
  industryType: string | null;
  subIndustryType: string | null;
  isSubIndustryManual: boolean;

  cinNumber: string | null;
  uanNumber: string | null;
  natureOfBusiness: string | null;
  reference1Name: string | null;
  reference2Name: string | null;
  reference1Contact: string | null;
  reference2Contact: string | null;
  officialEmail: string | null;
  officeStability: number | null;
  dateOfIncorporation: string | null;
}

export interface ApplicantDetails {
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  dateOfBirth: string | null;
  panNumber: string | null;
  currentAddress: string | null;
  currentAddressType: string | null;
  currentAddressState: string | null;
  currentAddressPincode: string | null;
  isPermanentAddressSameAsCurrentAddress: boolean | null;
  permanentAddress: string | null;
  permanentAddressType: string | null;
  permanentAddressState: string | null;
  permanentAddressPincode: string | null;
  residenceStability: number | null;
}

export interface Receivable {
  gstin: string | null;
  receivableAmountForNextFourMonths: number | null;
  status: ReceivableStatus | null;
  tradeName: string | null;
}

export interface EligibilityAmountDetails {
  loanCalculationType: LoanCalculationType | null;
  gstinLoginName: string | null;
  eligibilityAmount: number | null;
  interestRate: number | null;
  manualGstrDocuments: GstrDocument[];
}

export interface GstrDocument {
  id: number;
  s3Key: string;
  fileName: string;
  s3Url: string;
  docType: GstrDocumentType;
}
export interface CurrentBankAccountData {
  accountNumber: string;
  ifscCode: string;
  bankName: string;
}

export interface DisbursalBankAccountData {
  id: number;
  createdOn: string;
  updatedOn: string;
  accountNumber: string;
  bankName: string;
  ifscCode: string;
  status: string;
  proofS3Url: string | null;
  s3Key: string | null;
  fileName: string | null;
  createdBy: string | null;
  updatedBy: string | null;
  loanRequest: number;
}

export interface EligibilityDetails {
  readMoreUrl: string;
  prospectGstin: string;
  createdBy: {
    name: string;
    gender: string;
    phoneNumber: string;
    email: string;
    lastLogin: string;
    createdAt: string;
  };

  companyDetails: CompanyDetails | null;
  applicantDetails: ApplicantDetails | null;
  receivablesDetails: Receivable[];
  eligibilityAmountDetails: EligibilityAmountDetails | null;
  bankAccount: CurrentBankAccountData | null;
  disbursalBankAccount: DisbursalBankAccountData | null;
  applicationCreationDate: string | null;

  keyCustomers: Array<{ gstin: string }>;
  keySuppliers: Array<{ gstin: string }>;

  reason: string | null;
  isRejectReasonManual: boolean | null;
  status: EligibilityStatus | null;
  subStatus: SubStatus;
}

export type SaveEligibilityDetailsPayload = Partial<
  Pick<
    EligibilityDetails,
    "companyDetails" | "applicantDetails" | "bankAccount"
  > & {
    receivablesDetails: Array<Omit<Receivable, "status" | "tradeName">>;
    keyCustomers: Array<{ gstin: string }>;
    keySuppliers: Array<{ gstin: string }>;
  }
>;

export interface SaveEligibilityDetailsResponse {
  message: string;
}

export type UpdateLoanStatus =
  | { isAccepted: true }
  | {
      isAccepted: false;
      rejectReason: string;
      isRejectReasonManual: boolean;
    };

export interface AcceptRejectUpdatedAmount {
  isAccepted: boolean;
  anchorGstin: string;
}

//used for many dropdown data
export interface DropdownOptionsResponse {
  result: string[];
}

export interface Customer {
  gstin: string;
  tradeName: string;
}

export type CustomerListResponse = Customer[];

export interface ResponseWithStatus<
  S = EligibilityStatus | null,
  SS = SubStatus
> {
  message?: string;
  status: S;
  subStatus: SS;
}

export interface WepGenerateOtpRequest {
  gst_username: string;
}

export interface WepGenerateOtpResponse {
  message: string;
}

export interface WepVerifyOtpRequest {
  gst_username: string;
  otp: string;
  gstin: string;
}

export interface WepVerifyOtpResponse {
  success: boolean;
  data: { message: string };
}

export interface ResponseWithMessageData {
  success: boolean;
  data: { message: string };
}

export interface ResponseWithMessage {
  message: string;
}

export interface ResponseWithStatusCode {
  status: string;
}

export interface EligibilityFetchActionObject{
  eligibilityId: string;
  silent: boolean
}