import { IssueType } from "../../pages/loginIssue/LoginIssueConstants";
import { State } from "../../store/State";

export interface LoginPageRequest {
  phoneNumber: string;
  gstin: string;
}
export interface LoginResponse {
  success: boolean;
  message: string;
  isActive: boolean;
  token: string;
  gstin: string;
  gstUser: {
    id: number;
    name: string;
    gender: string;
    phoneNumber: string;
    email: string;
    lastLogin: string;
    createdAt: string;
    updatedAt: string | null;
    failedAttemptCount: number;
    autolocked: boolean;
    autolockedAt: string | null;
    disabled: boolean;
    eaNumber: string;
    pfNumber: string;
    imageUrl: string;
    s3Key: string;
    user: number;
  };
}

export type LoginRequest = LoginPageRequest | { token: string };

export interface LoginRequestPayload {
  data: LoginRequest;
  redirectTo?: (route: string, reLogging: boolean) => void;
}
export interface GstinListResponse {
  registeredGstins: GstinInactive[];
  success: boolean;
}
export interface GstinInactive {
  gstin: string;
  isInactive: boolean;
}
export interface EligibilityMetaDataResponse extends State {
  gstin: string;
  eligibilityId: string;
  legalName: string;
  primaryAddress: string;
  status: EligibilityStatus | null;
  isGstinInactive: boolean;
  subStatus: SubStatus;
  eligibilityAmount: number | null;
  amountUtilized: number | null;
  expiryDate?: string | null;
}

export interface SubStatus {
  kycStatus: KYCStatus | null;
  receivablesStatus: ReceivableStatus | null;
  disbursalBankAccountStatus: VirtualAccountStatus | null;
}

export interface ReportIssuePhoneAndOrigin {
  phoneNumber: string;
  fromLogin: boolean;
}

export interface LoginIssuePayload {
  issue: IssueType;
  phoneNumber: string;
  isLogin: boolean;
}

export interface LoginIssueResponse {
  success: boolean;
  message: string;
}

export interface LoginIssueState extends State {
  message: string;
  phoneNumber: string;
  fromLogin: boolean;
}

export enum EligibilityStatus {
  CompanyDetailsFold = "company_details_fold",
  ApplicantDetailsFold = "applicant_details_fold",
  ReceivablesFold = "receivables_fold",
  EligibilityAmountFold = "eligibility_amount_fold",
  InsufficientGSTRData = "insufficient_gstr_data_for_last_six_months",
  AwaitingPrincipleAmount = "awaiting_in_principle_eligibility_amount",
  PrincipleAmountShared = "in_principle_amount_shared",
  PrincipleAmountRejected = "in_principle_amount_rejected",
  PrincipleAmountAccepted = "in_principle_amount_accepted",
  OpportunityLost = "opportunity_lost",
  DocumentUploadPending = "pending_document_upload",
  ApplicationVerificationInProgress = "application_verification_in_progress",
  DMIInitiated = "dmi_initiated",
  ReadyForConversion = "eligibility_amount_approved_and_ready_for_conversion",
  CreditApproved = "credit_approved",
  ReadyForDisbursal = "ready_for_disbursal",
  ApplicationClosed = "application_closed",
  UpdateEligibilityAmount = "update_eligibility_amount_pending"
}

export enum KYCStatus {
  KYCPending = "kyc_pending",
  KYCOnHold = "kyc_on_hold_from_dmi",
  KYCApproved = "kyc_approved_from_dmi",
  KYCRejected = "kyc_rejected_from_dmi"
}

export enum ReceivableStatus {
  PendingVerification = "pending_for_verification",
  AcceptedByJitfin = "accepted_by_jitfin",
  RejectedByJitfin = "rejected_by_jitfin",
  AwaitingAcceptance = "updated_by_jitfin_await_prospect_approval",
  AcceptedByProspect = "accepted_by_prospect",
  RejectedByProspect = "rejected_by_prospect"
}

export enum VirtualAccountStatus {
  UploadPending = "await_proof",
  InVerification = "received_proof_pending_verification",
  Accepted = "proof_accepted",
  Rejected = "proof_rejected"
}
