import actionCreatorFactory from "typescript-fsa";
import {
  UpdateProfileResponse,
  UpdateProfileRequest,
  UserConsentObject
} from "../../services/profileService/ProfileService.type";

const actionCreator = actionCreatorFactory();

const UpdateProfileActions = actionCreator.async<
  UpdateProfileRequest,
  UpdateProfileResponse,
  Error
>("UPDATE_PROFILE");

const SetBorrowerConsent= actionCreator.async<UserConsentObject, any, Error>("SET_BORROWER_CONSENT")

export const profileActions = {
  updateProfile: UpdateProfileActions,
  setBorrowerConsent:SetBorrowerConsent
};
