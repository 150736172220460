/**
 * List all antd icons you want to use in your source code
 */
export { default as StarOutline } from "@ant-design/icons/lib/outline/StarOutline";

export { default as DownOutline } from "@ant-design/icons/lib/outline/DownOutline";

export { default as LoadingOutline } from "@ant-design/icons/lib/outline/LoadingOutline";

export { default as CloseCircleFill } from "@ant-design/icons/lib/fill/CloseCircleFill";

export { default as CloseOutline } from "@ant-design/icons/lib/outline/CloseOutline";
export { default as UploadOutline } from "@ant-design/icons/lib/outline/UploadOutline";
export { default as SyncOutline } from "@ant-design/icons/lib/outline/SyncOutline";
export { default as LineOutline } from "@ant-design/icons/lib/outline/LineOutline";
export { default as LeftOutline } from "@ant-design/icons/lib/outline/LeftOutline";

export { default as PlusCircle } from "@ant-design/icons/lib/outline/PlusCircleOutline";
export { default as CheckCircleFill } from "@ant-design/icons/lib/fill/CheckCircleFill";
export { default as Loading } from "@ant-design/icons/lib/outline/LoadingOutline";
export { default as CheckCircle } from "@ant-design/icons/lib/outline/CheckCircleOutline";
export { default as InfoCircle } from "@ant-design/icons/lib/outline/InfoCircleOutline";
export { default as ErrorCircle } from "@ant-design/icons/lib/outline/CloseCircleOutline";
export { default as ExclamationCircle } from "@ant-design/icons/lib/outline/ExclamationCircleOutline";
export { default as RightOutline } from "@ant-design/icons/lib/outline/RightOutline";

export { default as DownloadOutline } from "@ant-design/icons/lib/outline/DownloadOutline";
export { default as Bars } from "@ant-design/icons/lib/outline/BarsOutline";

export { default as InfoCircleFilled } from "@ant-design/icons/lib/fill/InfoCircleFill";
export { default as UpOutline } from "@ant-design/icons/lib/outline/UpOutline";
export { default as LogoutOutline } from "@ant-design/icons/lib/outline/LogoutOutline";

export { default as BankOutline } from "@ant-design/icons/lib/outline/BankOutline";
export { default as SwapOutline } from "@ant-design/icons/lib/outline/SwapOutline";
export { default as NumberOutline } from "@ant-design/icons/lib/outline/NumberOutline";

export { default as CaretUpFill } from "@ant-design/icons/lib/fill/CaretUpFill";
export { default as CaretDownFill } from "@ant-design/icons/lib/fill/CaretDownFill";
export { default as UserOutline } from "@ant-design/icons/lib/outline/UserOutline";
