// Generates url: {AppSettings.service.baseUrl}/{this.serviceApi}
// DO NOT append or prepend '/' here!
export enum ServiceType {
  Account = "gst-account",
  Anchor = "anchor",
  Eligibility = "loan-request",
  WEP = "wep-gst",
  DMI = "dmi",
  KYC = "kyc-document",
  Document = "document"
}
