export const regexList = {
  gstin: new RegExp(
    "(^[0-9]{2})([A-Za-z]{5}[0-9]{4}[A-Za-z]{1})[1-9A-Za-z]{1}[Zz]{1}[0-9A-Za-z]{1}$"
  ),
  accountNumber: new RegExp("^[0-9]{9,18}$"),
  ifscCode: new RegExp("^[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$"),
  phoneNumber: new RegExp("^[0-9]{10}$"),
  cinNumber: new RegExp(
    "^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$"
  ),
  uanNumber: new RegExp("^(\\d{12})$"),
  digits: new RegExp("[0-9]"),
  minimum1Character: new RegExp("^.*\\S.*$"),
  alphaNumerical: new RegExp("^[a-zA-Z0-9]*$")
};

export const MOBILE_COUNTRY_CODE = 91;
export interface StringValidPair {
  value: string;
  isValid?: boolean;
}

export const API_ERROR_FEEDBACK = {
  error: "Something went wrong",
  saveError: "Unable to save data",
  downloadError: "Unable to download",
  sessionExpired: "Session Expired, please login again"
};

export const DEFAULT_FILE_CONFIG: FileConfig = {
  acceptableFileExtensions: ["pdf"],
  maximumFileSize: 10000000, // specified in bytes - 1MB
  errorMessages: {
    invalidFileExtension: "File Extension not supported",
    invalidFileSize: "File exceeds the maximum size(10 MB)"
  }
};
export interface FileConfig {
  acceptableFileExtensions: string[];
  maximumFileSize: number;
  errorMessages: {
    [key: string]: any;
  };
}

export const unsavedChangeAlert =
  "There are unsaved changes in the page,proceeding might clear the current changes. Do you want to proceed?";

//table constants
export const TABLE_ROW_CLASS_NAME = (_: any, index: number) =>
  index % 2 === 0 ? "even-table-row" : "odd-table-row";

export const TABLE_TOTAL_COUNT = (total: number, range: [number, number]) =>
  `${range[0]}-${range[1]} of ${total} items`;

export const DATE_FORMAT = "DD-MM-YYYY";

export const LIST_FETCH_SIZE = 15;

export enum SORT_ORDER {
  Ascend = "ascend",
  Descend = "descend"
}
