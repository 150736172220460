import { store } from "../../store/Store";

import {
  EligibilityDetails,
  CompanyDetails,
  Receivable
} from "../../services/eligibilityService/EligibilityService.type";
import { EligibilityDetailsState, CompanyDetailsState } from "./state";
import { MultiTags } from "../../components/formHelpers/FormMultiInputTags";
import { ApplicantDetailsState } from "../../pages/eligibilityView/applicantDetailsFold/ApplicantDetailsFold";

const getCompanyDetailsDefaultState = (): CompanyDetailsState => ({
  cinNumber: null,
  companyType: null,
  currentCustomer: "",
  currentSupplier: "",
  employeesCount: null,
  industryType: null,
  isSubIndustryManual: false,
  keyCustomers: [],
  keySuppliers: [],
  subIndustryManual: "",
  state: null,
  pincode: null,
  subIndustryType: null,
  uanNumber: null,
  natureOfBusiness: null,
  reference1Name: null,
  reference2Name: null,
  reference1Contact: "",
  reference2Contact: "",
  officialEmail: "",
  officeStability: null,
  dateOfIncorporation: null,
  unitAddress: store.getState().loginState.eligibilityMetaData.primaryAddress,
  unitAddressType: null
});

const applicantDetailsInitialState: ApplicantDetailsState = {
  firstName: null,
  lastName: null,
  middleName: "",
  currentAddress: "",
  currentAddressType: null,
  currentAddressPincode: null,
  currentAddressState: null,
  dateOfBirth: null,
  isPermanentAddressSameAsCurrentAddress: false,
  panNumber: null,
  permanentAddress: null,
  permanentAddressType: null,
  permanentAddressState: null,
  permanentAddressPincode: null,
  residenceStability: null
};

const receivablesDetailsInitialState: Receivable[] = [
  {
    gstin: null,
    receivableAmountForNextFourMonths: null,
    status: null,
    tradeName: null
  }
];

export const subIndustryOthers = "Others";

const toMultiTags = (data: Array<{ gstin: string }>): MultiTags[] =>
  data?.map((item, index) => ({
    id: index,
    value: item.gstin,
    removed: false,
    loading: false
  }));

const formatCompanyDetails = (data: {
  companyDetails: CompanyDetails;
  keyCustomers: MultiTags[];
  keySuppliers: MultiTags[];
}): CompanyDetailsState => {
  const { companyDetails, keyCustomers, keySuppliers } = data;
  const { isSubIndustryManual, subIndustryType, ...others } = companyDetails;

  return {
    ...others,
    keyCustomers,
    keySuppliers,
    //set "subIndustryManual" only if data is entered manually.
    subIndustryManual:
      isSubIndustryManual && subIndustryType ? subIndustryType : "",
    //sub_industry_type is treated as "subIndustryOthers" if user entered it manually
    subIndustryType: isSubIndustryManual ? subIndustryOthers : subIndustryType,
    isSubIndustryManual,

    currentCustomer: "",
    currentSupplier: ""
  };
};

export const formatEligibilityDetails = (
  data: EligibilityDetails
): EligibilityDetailsState => {
  const {
    companyDetails,
    applicantDetails,
    reason,
    isRejectReasonManual,
    eligibilityAmountDetails,
    bankAccount,
    receivablesDetails,
    keyCustomers,
    keySuppliers,
    ...others
  } = data;
  return {
    ...others,
    companyDetails: companyDetails
      ? formatCompanyDetails({
          companyDetails,
          keyCustomers: toMultiTags(keyCustomers),
          keySuppliers: toMultiTags(keySuppliers)
        })
      : getCompanyDetailsDefaultState(),
    applicantDetails: applicantDetails
      ? applicantDetails
      : applicantDetailsInitialState,
    receivablesDetails: receivablesDetails.length
      ? receivablesDetails
      : receivablesDetailsInitialState,
    eligibilityAmountDetails: eligibilityAmountDetails
      ? eligibilityAmountDetails
      : {
          eligibilityAmount: null,
          gstinLoginName: null,
          interestRate: null,
          loanCalculationType: null,
          manualGstrDocuments: []
        },

    isRejectReasonManual,
    reason: isRejectReasonManual ? null : reason,
    manualReason: isRejectReasonManual ? reason : null,
    bankAccount: bankAccount || {
      ifscCode: "",
      bankName: "",
      accountNumber: ""
    }
  };
};
