import { css } from "@emotion/core";

export const color = {
  primary: "#2d885a",

  secondary: "#072439",

  light: "#808080",

  red: "#ff0000",
  green: "#008000",
  fiord: "#585c5f",

  placeholder: "#bfbfbf",

  danger: "#f1553c",

  bold: "#575757",
  border: "#d9d9d9",

  boxShadow: "rgba(0, 0, 0, 0.1)",
  boxShadowDark: "rgba(0, 0, 0, 0.16)",
  overlay: "rgba(0, 0, 0, 0.74)",
  disabledBackground: "rgba(191, 191, 191, 0.3)",
  disabledDate: "rgba(0, 0, 0, 0.25)",
  disabledDateBackground: "#f5f5f5",
  greenBackground: "#007733",

  scrollbarThumb: "#c4c8d8",
  scrollbarTrack: "#d7dae280",

  activeBackground: "#f1f5f3",

  lightBlue: "#2d72d3",

  lightGrey: "#7f7f7f",
  darkGrey: "#525252",

  lightYellow: "#F3E1C0",
  melon: "#F6ADAD",
  lightGreen: "#CCEAD8",
  darkGreen: "#2e885a",
  white: "#ffffff",
  greenOverlay: "rgba(46, 136, 90, 0.9)",
  successTextGreen: "#52c41a",

  black: "#000000",
  modalRed: "#ef6d6d",

  progressDarkBlue: "#7da8fa",
  pink: "#fe9f9e",
  progressTrail: "#e2eafc",

  yellow: "#faad14",

  // Feedback colors.
  successFeedbackText: "#155724",
  successFeedbackBackground: "#d4edda",
  successFeedbackBorder: "#c3e6cb",
  errorFeedbackText: "#721c24",
  errorFeedbackBackground: "#f8d7da",
  errorFeedbackBorder: "#f5c6cb",
  infoFeedbackText: "#0c5460",
  infoFeedbackBackground: "#d1ecf1",
  infoFeedbackBorder: "#bee5eb"
};

export const fonts = {
  regular: "Silika Medium",
  bold: "Silika Black Bold"
};

export const fontSizes = {
  title: "18px",
  label: "14px",
  placeholder: "16px",
  buttonText: "18px",
  error: "14px",
  cardLabel: "20px",
  heading: "22px",
  eligibilityLabel: "24px",
  tickIcon: "50px",
  h3Size: "26px",
  disbursalIcons: "32px"
};

export const breakpoints = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px"
};

export const theme = css({
  // Custom scrollbar.
  "::-webkit-scrollbar": {
    width: "0.5rem",
    height: "0.5rem",
    background: color.scrollbarTrack
  },
  "::-webkit-scrollbar-thumb": {
    borderRadius: "0.25rem",
    background: color.scrollbarThumb
  },

  ".anchor-link": {
    color: color.lightBlue,
    "&:hover, &:focus, &:active": {
      color: color.lightBlue,
      textDecoration: "underline"
    }
  },
  ".error, .ant-typography.error": {
    color: color.red,
    textAlign: "right",
    clear: "right",
    fontSize: fontSizes.error
  },
  label: {
    fontSize: fontSizes.label,
    fontFamily: fonts.regular,
    color: color.secondary
  },
  ".placeholder, input::placeholder": {
    fontSize: fontSizes.placeholder,
    fontFamily: fonts.regular,
    color: color.placeholder
  },

  ".App, body": {
    fontFamily: fonts.regular,

    //Table Styles
    ".ant-table-thead > tr > th": {
      backgroundColor: color.activeBackground,
      fontFamily: fonts.bold,
      fontSize: fontSizes.placeholder
    },
    ".ant-table-thead > tr > th, .ant-table-tbody > tr > td": {
      textAlign: "center",
      color: color.darkGrey
    },
    ".ant-table-tbody > .odd-table-row > td.ant-table-column-sort ": {
      background: color.activeBackground
    },
    ".odd-table-row": {
      backgroundColor: color.activeBackground
    },
    // Pagination styling.
    ".ant-pagination": {
      marginTop: "1rem",
      display: "flex",
      justifyContent: "flex-end"
    },

    // Override antd component styles here.
    ".ant-spin-nested-loading > div > .ant-spin": {
      maxHeight: "unset"
    },
    // Contains style overrides for DatePicker, Select, Input.
    ".ant-calendar-date": {
      borderRadius: "50%"
    },
    ".ant-calendar-selected-day .ant-calendar-date": {
      color: "#fff",
      backgroundColor: color.primary,
      fontWeight: "normal"
    },
    [`.ant-select-dropdown-menu-item-selected, .ant-select-dropdown-menu-item:hover, 
    .ant-calendar-header .ant-calendar-month-select, 
    .ant-calendar-header .ant-calendar-year-select, 
    .ant-calendar-header .ant-calendar-month-panel-year-select, 
    .ant-calendar-header .ant-calendar-year-panel-decade-select, 
    .ant-calendar-decade-panel-century`]: {
      color: color.primary,
      fontFamily: fonts.bold
    },
    ".ant-calendar-disabled-cell .ant-calendar-date": {
      width: "24px",
      height: "24px",
      margin: "0 auto",
      borderRadius: "50%"
    },
    ".ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before": {
      border: "none",
      background: "none"
    },
    ".ant-calendar-today .ant-calendar-date": {
      background: color.disabledDateBackground,
      color: color.disabledDate
    },
    ".ant-calendar-picker-clear": {
      height: "18px",
      width: "18px",
      right: "8px"
    },
    ".ant-select-disabled .ant-select-selection": {
      background: "none"
    },
    ".ant-select-selection, .ant-select-open .ant-select-selection, .ant-input, .ant-input-number": {
      border: "none",
      borderBottom: `1px solid ${color.border}`,
      borderRadius: "0",
      boxShadow: "none",
      "&:hover, &:active, &:focus": {
        boxShadow: "none",
        borderColor: color.primary
      }
    },
    ".ant-input,.ant-input-number": {
      paddingLeft: "0"
    },
    ".ant-input-number": {
      width: "100%"
    },

    ".ant-select-selection__rendered": {
      marginLeft: "0"
    },
    ".ant-input[disabled], .ant-input-number-disabled": {
      background: "none"
    },
    ".ant-tag-ellipse": {
      background: color.white,
      borderRadius: "1.25rem"
    },
    ".ant-tag-grey": {
      color: color.darkGrey,
      border: `1px solid ${color.darkGrey}`
    },
    ".ant-tag-green": {
      color: color.secondary,
      border: `1px solid ${color.primary}`
    },
    ".ant-tag-red": {
      color: color.red,
      border: `1px solid ${color.red}`
    },
    ".ant-tag-name-ellipsis": {
      width: "90%",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    ".ant-tooltip-inner, .ant-tooltip-arrow::before": {
      backgroundColor: color.fiord,
      fontSize: fontSizes.label,
      whiteSpace: "pre-line" // required to display line breaks
    },

    //divider
    ".ant-divider-vertical.ant-divider-dashed": {
      borderWidth: "0px 0px 10px 2px"
    },

    // Switch Styles
    ".ant-switch": {
      height: "2px",
      width: "2rem"
    },
    ".ant-switch::after": {
      backgroundColor: color.primary,
      width: "14px",
      height: "14px",
      top: "-7px"
    },
    ".ant-switch-checked": {
      backgroundColor: color.placeholder
    },

    // Feedback styling.
    ".ant-message-notice-content": {
      padding: 0,
      borderRadius: "0.25rem",
      background: "unset",
      ".ant-message-custom-content": {
        borderRadius: "0.25rem",
        padding: "0.625rem 1rem"
      },
      ".ant-message-error": {
        background: color.errorFeedbackBackground,
        color: color.errorFeedbackText,
        border: `solid 1px ${color.errorFeedbackBorder}`,
        ".anticon": {
          color: color.errorFeedbackText
        }
      },
      ".ant-message-success": {
        backgroundColor: color.successFeedbackBackground,
        color: color.successFeedbackText,
        border: `solid 1px ${color.successFeedbackBorder}`,
        ".anticon": {
          color: color.successFeedbackText
        }
      },
      ".ant-message-info": {
        backgroundColor: color.infoFeedbackBackground,
        color: color.infoFeedbackText,
        border: `solid 1px ${color.infoFeedbackBorder}`,
        ".anticon": {
          color: color.infoFeedbackText
        }
      }
    }
  }
});
