import ApiServiceBase from "../ApiServiceBase";
import ApiService from "../ApiService";
import { ServiceType } from "../ServiceType.data";
import { QueryPath } from "../../services/QueryPath.data";
import {
  SaveEligibilityDetailsPayload,
  DropdownOptionsResponse,
  WepGenerateOtpRequest,
  WepGenerateOtpResponse,
  WepVerifyOtpRequest,
  CustomerListResponse,
  UpdateLoanStatus,
  ResponseWithStatus,
  AcceptRejectUpdatedAmount,
  EligibilityDetails,
  WepVerifyOtpResponse,
  GetStartedLoanCalculation,
  LoanCalculationResponse,
  ResponseWithMessage
} from "./EligibilityService.type";

class EligibilityService {
  public uploadGstrConfig: QueryPath = { route: ["upload-document"] };
  public deleteGstrConfig: QueryPath = { route: ["delete-gst-doc"] };

  public uploadDisbursalAccountProof: QueryPath = {
    route: ["disbursal-bank-account", "proof"]
  };

  public initializeLoanApplication() {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);

    return service.post({ route: ["initiate"] }, {});
  }

  public getEligibilityMetaData() {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.get({
      route: ["status"]
    });
  }

  public eligibilityDetails():
    | EligibilityDetails
    | Promise<EligibilityDetails> {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.get({});
  }
  public saveEligibilityDetails(
    data: SaveEligibilityDetailsPayload
  ): EligibilityDetails | Promise<EligibilityDetails> {
    const service: ApiServiceBase = new ApiService(
      ServiceType.Eligibility,
      true
    );

    return service.patch({}, data);
  }

  public getIndustries():
    | DropdownOptionsResponse
    | Promise<DropdownOptionsResponse> {
    const service: ApiServiceBase = new ApiService(ServiceType.Anchor);

    return service.get({ route: ["industry-type"] });
  }

  public getSubIndustries(
    industry: string
  ): DropdownOptionsResponse | Promise<DropdownOptionsResponse> {
    const service: ApiServiceBase = new ApiService(ServiceType.Anchor);

    return service.get({ route: ["sub-industries"], query: { industry } });
  }

  public getCustomerList():
    | CustomerListResponse
    | Promise<CustomerListResponse> {
    const service: ApiServiceBase = new ApiService(ServiceType.Anchor);
    return service.get({});
  }

  public generateOTP(
    data: WepGenerateOtpRequest
  ): WepGenerateOtpResponse | Promise<WepGenerateOtpResponse> {
    const service: ApiServiceBase = new ApiService(ServiceType.WEP, true);
    // return Promise.resolve({"success":true,"data":{"message":"otp sent successfully"}} as any);
    return service.post({ route: ["generate-otp"] }, data);
  }

  public verifyOTP(
    data: WepVerifyOtpRequest
  ): WepVerifyOtpResponse | Promise<WepVerifyOtpResponse> {
    const service: ApiServiceBase = new ApiService(ServiceType.WEP, true);
    //   return Promise.resolve( {
    //  "success": true,
    //  "data": {
    //      "message": "otp verified successfully"
    //  }
    // })
    return service.post({ route: ["validate-otp"] }, data);
  }

  public updateStatus(
    data: UpdateLoanStatus
  ): ResponseWithStatus | Promise<ResponseWithStatus> {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.post({ route: ["status"] }, data);
  }

  public triggerManualFileVerification() {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);

    return service.post({ route: ["manual-file-verification"] }, {});
  }

  public eligibilityAmountRejectionReasons(): Promise<string[]> | string[] {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);

    return service.get({ route: ["view-reason"] });
  }

  public acceptRejectUpdatedEligibilityAmount(
    data: AcceptRejectUpdatedAmount
  ): ResponseWithStatus | Promise<ResponseWithStatus> {
    const service: ApiServiceBase = new ApiService(ServiceType.Anchor);

    return service.post({ route: ["update-receivables-status"] }, data);
  }

  public startLoanCalculation(data: GetStartedLoanCalculation): ResponseWithMessage | Promise<ResponseWithMessage> {
    const service: ApiServiceBase = new ApiService(ServiceType.WEP);
    // return {"success":true,"data":{"status":"FAILED", "message": "loan calculation has failed"}} as any;
    // return {"success":true,"data":{"status":"IN-PROGRESS"}} as any;
    // return {"success":true,"data":{"message":"loan calculation status is STARTED"}} as any;
    return service.post({ route: ["instance-loan-calculation"] }, data);
  }

  public fetchLoanCalculation(data: string, hideFeedback = false): LoanCalculationResponse | Promise<LoanCalculationResponse> {
    const service: ApiServiceBase = new ApiService(ServiceType.WEP, hideFeedback);
    // return {"success":true,"data":{"status":"IN-PROGRESS"}} as any;
    return service.get({ route: ["instance-loan-calculation", data] });
  }
}

const eligibilityService = new EligibilityService();
export default eligibilityService;
