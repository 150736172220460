import * as Yup from "yup";
import { regexList, FileConfig } from "../../Constants";
import { MultiTags } from "../../components/formHelpers/FormMultiInputTags";
export const ProfileContents = {
  titles: {
    heading: "Profile Details"
  },
  labels: {
    profilePic: "Upload Profile Picture",
    gstins: "Gstins: ",
    addUpdateGstin: "Add and Update GSTINs",
    addedGstin: "Added GSTIN Number",
    action: "Action",
    disableAction: "Disable",
    enableAction: "Enable",
    eaNumber: "EA Number",
    pfNumber: "PF Registration Number",
    currentAccount: "Current Account Number",
    bankName: "Bank Name",
    ifscCode: "IFSC Code",
    activeGst: "Active GST Number",
    keyCustomers: "Key Customers",
    keySuppliers: "Key Suppliers"
  },
  placeholders: {
    eaNumber: "Enter EA number",
    pfNumber: "Enter PF registration number",
    accountNumber: "Enter Account number",
    bankName: "Enter Bank name",
    ifscCode: "Enter IFSC Code",
    gstin: "Enter GSTIN number"
  },
  buttons: {
    uploadImage: "Upload Profile Picture",
    update: "Update"
  },
  feedbacks: {
    profilePic: "Please upload Profile pic",
    invalidGstin: "Invalid gstin",
    profileUpdateSuccess: "Profile Updated Successfully",
    profileSwitchSuccess: "Successfully Changed Active GSTIN"
  }
};

export const ImageFileConstants: FileConfig = {
  acceptableFileExtensions: [".png", ".jpeg", ".jpg"],
  maximumFileSize: 10000000, // specified in bytes
  errorMessages: {
    invalidFileExtension: "You can only upload JPG/PNG file!",
    invalidFileSize: "File exceeds the maximum size(10 MB)"
  }
};

const errorMessages = {
  invalidGstin: "Enter a valid Gstin",
  invalidFormat: "Invalid format",
  gstinLoading: "GSTIN is being authenticated",
  duplicate: "GSTIN already exists",

  required: "Required",
  userGstin: "Can't be your own GSTIN"
};

export const ProfileValidationSchema = (gstin: string) =>
  Yup.object().shape<ValidationSchemaType>({
    currentCustomer: Yup.string()
      .uppercase()
      .matches(regexList.gstin, errorMessages.invalidFormat)
      .notOneOf([gstin], errorMessages.userGstin)
      .test({
        name: "Duplicate GSTIN check",
        test(value) {
          return this.parent.formattedKeyCustomers
            .filter((item: MultiTags) => !item.removed)
            .find((item: MultiTags) => item.value === value)
            ? this.createError({
                message: errorMessages.duplicate
              })
            : true;
        }
      }),
    currentSupplier: Yup.string()
      .uppercase()
      .matches(regexList.gstin, errorMessages.invalidFormat)
      .notOneOf([gstin], errorMessages.userGstin)
      .test({
        name: "Duplicate GSTIN check",
        test(value) {
          return this.parent.formattedKeySuppliers
            .filter((item: MultiTags) => !item.removed)
            .find((item: MultiTags) => item.value === value)
            ? this.createError({
                message: errorMessages.duplicate
              })
            : true;
        }
      }),
    gstinField: Yup.string()
      .uppercase()
      .matches(regexList.gstin, errorMessages.invalidGstin)
      .test({
        name: "Duplicate GSTIN check",
        test(value) {
          return this.parent.registeredGstins.find(
            (item: GstinStatusPair) => item.gstin === value
          )
            ? this.createError({
                message: errorMessages.duplicate
              })
            : true;
        }
      }),
    formattedKeyCustomers: Yup.array<MultiTags>().of(
      Yup.object().shape({
        value: Yup.string().matches(regexList.gstin),
        loading: Yup.boolean().oneOf([false], errorMessages.gstinLoading)
      })
    ) as Yup.Schema<MultiTags[]>,
    formattedKeySuppliers: Yup.array<MultiTags>().of(
      Yup.object().shape({
        value: Yup.string().matches(regexList.gstin),
        loading: Yup.boolean().oneOf([false], errorMessages.gstinLoading)
      })
    ) as Yup.Schema<MultiTags[]>,
    accountNumber: Yup.string().matches(
      regexList.accountNumber,
      errorMessages.invalidFormat
    ),
    ifscCode: Yup.string().matches(
      regexList.ifscCode,
      errorMessages.invalidFormat
    ),
    bankName: Yup.string(),
    eaNumber: Yup.string()
      .matches(regexList.digits, errorMessages.invalidFormat)
      .nullable(),
    pfNumber: Yup.string()
      .matches(regexList.digits, errorMessages.invalidFormat)
      .nullable()
  });

interface ValidationSchemaType {
  currentCustomer: string;
  currentSupplier: string;
  gstinField: string;
  formattedKeyCustomers: object;
  formattedKeySuppliers: object;
  accountNumber: string;
  ifscCode: string;
  bankName: string;
  eaNumber: string | null;
  pfNumber: string | null;
}

export interface GstinStatusPair {
  gstin: string;
  isEnabled: boolean;
}
