import ApiServiceBase from "../ApiServiceBase";
import ApiService from "../ApiService";
import { ServiceType } from "../ServiceType.data";
import {
  UpdateProfilePayload,
  GetProfileDetailsResponse,
  UserConsentObject
} from "./ProfileService.type";

const service: ApiServiceBase = new ApiService(ServiceType.Account);

class ProfileService {
  public updateProfileDetails(data: UpdateProfilePayload) {
    //Post multipart accepts string values
    const registeredGstins = JSON.stringify(data.registeredGstins);
    const keyCustomers = JSON.stringify(data.keyCustomers);
    const keySuppliers = JSON.stringify(data.keySuppliers);
    const payload = { ...data, registeredGstins, keyCustomers, keySuppliers };
    return service.postMultipart({ route: ["profile"] }, payload);
  }
  public getProfileDetails():
    | GetProfileDetailsResponse
    | Promise<GetProfileDetailsResponse> {
    return service.get({ route: ["profile"] });
  }

  public setBorrowerConsent(data:UserConsentObject):any{
    return service.post({route:['conscensus']}, data);
  }
}

const profileService = new ProfileService();
export default profileService;
