import ApiServiceBase from "../ApiServiceBase";
import ApiService from "../ApiService";
import { ServiceType } from "../ServiceType.data";
import { RegistrationRequestPayload } from "./RegistrationService.type";

const service: ApiServiceBase = new ApiService(ServiceType.Account);

class RegistrationService {
  public registration(data: RegistrationRequestPayload) {
    return service.post({ route: ["register/customer-portal"] }, data);
  }
}

const registrationService = new RegistrationService();
export default registrationService;
