import ApiServiceBase from "../ApiServiceBase";
import ApiService from "../ApiService";
import { ServiceType } from "../ServiceType.data";
import {
  LoginRequest,
  LoginIssuePayload,
  GstinListResponse
} from "./AuthService.type";

const service: ApiServiceBase = new ApiService(ServiceType.Account);
class AuthService {
  public login(data: LoginRequest) {
    if ("phoneNumber" in data) {
      const { phoneNumber, gstin } = data;
      const requestPayload = { phone_number: phoneNumber, gstin };
      return service.post({ route: ["login/customer-portal"] }, requestPayload);
    } else {
      const { token } = data;
      const requestPayload = { token };
      return service.post({ route: ["login/customer-portal"] }, requestPayload);
    }
  }
  public getGstinsList(
    data: string
  ): GstinListResponse | Promise<GstinListResponse> {
    return service.get({
      route: ["registered-gstins/customer-portal"],
      query: { phone_number: data }
    });
  }
  public loginIssue(data: LoginIssuePayload) {
    return service.post({ route: ["report-login-issue/customer-portal"] }, data);
  }

  public logout() {
    return service.post({ route: ["logout"] }, {});
  }
}

const authService = new AuthService();
export default authService;
