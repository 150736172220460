import moment from "moment";

export const throttle = <T extends (...args: any) => any>(
  func: T,
  wait: number
) => {
  let lastTime = moment();

  return (...args: any) => {
    const now = moment();

    if (now.diff(lastTime, "milliseconds") >= wait) {
      func(...args);
      lastTime = now;
    }
  };
};
