import { takeEvery, takeLatest, put, call } from "redux-saga/effects";
import { message } from "antd";

import {
  eligibilityActions,
  SaveEligibilityRequest,
  GetEligibilityDetails
} from "./actions";
import { authActions } from "../../store/auth/actions";
import { Action } from "typescript-fsa";
import { EligibilityService } from "../../services/eligibilityService";

import {
  EligibilityDetails,
  DropdownOptionsResponse,
  CustomerListResponse,
  GetStartedEligibilityRequest,
  GetStartedLoanCalculation,
  LoanCalculationResponse,
  ResponseWithStatusCode,
  LoanCalculationStatus,
  EligibilityFetchActionObject
} from "../../services/eligibilityService/EligibilityService.type";
import { EligibilityMetaDataResponse, EligibilityStatus } from "../../services/authService/AuthService.type";
import { formatEligibilityDetails } from "./util";
import { API_ERROR_FEEDBACK } from "../../Constants";

function* fetchLoanCalculationWorker(action:Action<EligibilityFetchActionObject>){
  try{
    const response:LoanCalculationResponse = yield EligibilityService.fetchLoanCalculation(action.payload.eligibilityId,action.payload.silent)
    yield put(eligibilityActions.fetchLoanCalculation.done({result:response,params:action.payload}))
  }catch(error){
    yield put(eligibilityActions.fetchLoanCalculation.failed(error.message || error))
  }
}
function* StartLoanCalculationWorker(action:Action<GetStartedLoanCalculation>){
  try{
    const response:ResponseWithStatusCode = yield EligibilityService.startLoanCalculation(action.payload)
    yield put(eligibilityActions.startLoanCalculation.done({result:response, params:action.payload}))
    //updating status
    // eslint-disable-next-line eqeqeq
    if(
      (response as any).data?.status === LoanCalculationStatus.IN_PROGRESS.toString()
        ||
      ((response as any).data?.message as string)?.indexOf('STARTED') > -1){
      yield put(authActions.updateStatus({status: EligibilityStatus.AwaitingPrincipleAmount}));
    } else{
      throw response;
    }
  }catch(error){
    yield put(eligibilityActions.startLoanCalculation.failed(error.message || error))
    yield put(authActions.updateStatus({status: EligibilityStatus.InsufficientGSTRData}));
  }
}

function* EligibilityInitWorker(action: Action<GetStartedEligibilityRequest>) {
  try {
    const { redirect } = action.payload;
    yield EligibilityService.initializeLoanApplication();
    const eligibilityStatusResponse: EligibilityMetaDataResponse = yield call(
      EligibilityService.eligibilityMetaData
    );
    yield put(
      authActions.getEligibilityMetaData.done({
        params: undefined,
        result: { ...eligibilityStatusResponse }
      })
    );
    if (redirect) {
      redirect();
    }
  } catch (error) {
    yield put(
      eligibilityActions.initializeEligibilityId.failed(error.message || error)
    );
  }
}

function* EligibilityDetailsWorker(action: Action<GetEligibilityDetails>) {
  try {
    const { onSuccess } = action.payload;

    const response: EligibilityDetails = yield EligibilityService.eligibilityDetails();

    const formattedResponse = formatEligibilityDetails(response);

    //updating status
    yield put(
      authActions.updateStatus({
        status: response.status,
        subStatus: response.subStatus
      })
    );

    yield put(
      eligibilityActions.eligibilityDetails.done({
        result: formattedResponse,
        params: action.payload
      })
    );

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(
      eligibilityActions.eligibilityDetails.failed(error.message || error)
    );
  }
}

function* IndustriesWorker(_: Action<void>) {
  try {
    const response: DropdownOptionsResponse = yield EligibilityService.getIndustries();
    yield put(
      eligibilityActions.getIndustries.done({
        result: response.result
      })
    );
  } catch (error) {
    yield put(eligibilityActions.getIndustries.failed(error.message || error));
  }
}

function* SubIndustriesWorker(action: Action<string>) {
  try {
    const response: DropdownOptionsResponse = yield EligibilityService.getSubIndustries(
      action.payload
    );
    yield put(
      eligibilityActions.getSubIndustries.done({
        result: response.result,
        params: action.payload
      })
    );
  } catch (error) {
    yield put(
      eligibilityActions.getSubIndustries.failed(error.message || error)
    );
  }
}

function* CustomerListWorker(action: Action<void>) {
  try {
    const response: CustomerListResponse = yield EligibilityService.getCustomerList();
    yield put(
      eligibilityActions.getCustomerList.done({
        result: response,
        params: action.payload
      })
    );
  } catch (error) {
    yield put(
      eligibilityActions.getCustomerList.failed(error.message || error)
    );
  }
}

function* SaveEligibilityDetailsWorker(action: Action<SaveEligibilityRequest>) {
  const { data, onError, onSuccess } = action.payload as SaveEligibilityRequest;
  try {
    const response: EligibilityDetails = yield EligibilityService.saveEligibilityDetails(
      data
    );

    //updating status
    yield put(
      authActions.updateStatus({
        status: response.status,
        subStatus: response.subStatus
      })
    );

    if (onSuccess) {
      onSuccess();
    }

    yield put(
      eligibilityActions.saveEligibilityDetails.done({
        params: action.payload,
        result: formatEligibilityDetails(response)
      })
    );
  } catch (error) {
    if (onError) {
      onError(error.message || error);
    }
    message.error({ content: error.message || API_ERROR_FEEDBACK.saveError });
    yield put(
      eligibilityActions.saveEligibilityDetails.failed(error.message || error)
    );
  }
}

function* EligibilityWatcher() {
  yield takeEvery(
    eligibilityActions.eligibilityDetails.started,
    EligibilityDetailsWorker
  );
  yield takeEvery(
    eligibilityActions.saveEligibilityDetails.started,
    SaveEligibilityDetailsWorker
  );
  yield takeLatest(eligibilityActions.getIndustries.started, IndustriesWorker);
  yield takeEvery(
    eligibilityActions.getCustomerList.started,
    CustomerListWorker
  );
  yield takeLatest(
    eligibilityActions.getSubIndustries.started,
    SubIndustriesWorker
  );
  yield takeEvery(
    eligibilityActions.initializeEligibilityId.started,
    EligibilityInitWorker
  );
  yield takeEvery(
    eligibilityActions.startLoanCalculation.started,
    StartLoanCalculationWorker
  );
  yield takeEvery(
    eligibilityActions.fetchLoanCalculation.started,
    fetchLoanCalculationWorker
  );
}

export default EligibilityWatcher;
