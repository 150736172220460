export enum LogLevel {
  None,
  Errors,
  Debug,
  Trace
}

enum defaultLogLevels {
  development = LogLevel.Trace,
  production = LogLevel.Errors,
  test = LogLevel.Debug
}

//set REACT_APP_BASE_URL in .env file
const BaseUrl = process.env.REACT_APP_BASE_URL as string;
const RecaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY as string;

export class AppSettings {
  public static readonly server = {
    baseUrl: BaseUrl,
    recaptchaKey: RecaptchaKey
  };
  public static readonly logging = {
    logLevel: defaultLogLevels[process.env.NODE_ENV]
  };
}
