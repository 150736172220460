import actionCreatorFactory from "typescript-fsa";
import {
  LoginRequestPayload,
  LoginResponse,
  LoginIssuePayload,
  LoginIssueResponse,
  ReportIssuePhoneAndOrigin,
  EligibilityMetaDataResponse
} from "../../services/authService/AuthService.type";
import { ActionWithCallback } from "../ActionWithCallback";
import { ResponseWithStatus } from "../../services/eligibilityService/EligibilityService.type";
import { RedirectSelector } from "../../helperHooks/useRedirect";

export type LoginIssueRequestData = ActionWithCallback<
  LoginIssuePayload,
  string
>;

export type UpdateStatus = Partial<
  Pick<ResponseWithStatus, "status" | "subStatus">
>;

const actionCreator = actionCreatorFactory();

const LoginActions = actionCreator.async<
  LoginRequestPayload,
  LoginResponse,
  Error
>("LOGIN");

const ReportLoginIssueActions = actionCreator.async<
  LoginIssueRequestData,
  LoginIssueResponse,
  Error
>("REPORT_LOGIN_ISSUE");

const SetReportIssuePhoneNumber = actionCreator<ReportIssuePhoneAndOrigin>(
  "SET_PHONE_NUMBER_AND_FROM_LOGIN"
);

const GetEligibilityMetaDataActions = actionCreator.async<
  RedirectSelector | undefined,
  EligibilityMetaDataResponse,
  Error
>("CHECK_ELIGIBILITY");

const updateStatusAction = actionCreator<UpdateStatus>("UPDATE_STATUS");

const updateGstinStatusAction = actionCreator<boolean>("UPDATE_GSTIN_STATUS");

export const authActions = {
  login: LoginActions,
  reportLoginIssue: ReportLoginIssueActions,
  setIssuePhoneNumber: SetReportIssuePhoneNumber,
  getEligibilityMetaData: GetEligibilityMetaDataActions,
  updateStatus: updateStatusAction,
  updateGstinStatus: updateGstinStatusAction
};
