import actionCreatorFactory from "typescript-fsa";
import {
  SaveEligibilityDetailsPayload,
  Customer,
  InitializeEligibilityIdResponse,
  GetStartedEligibilityRequest,
  LoanCalculationType,
  GetStartedLoanCalculation,
  LoanCalculationResponse,
  ResponseWithStatusCode,
  ResponseWithMessage,
  EligibilityFetchActionObject
} from "../../services/eligibilityService/EligibilityService.type";
import { ActionWithCallback } from "../ActionWithCallback";
import { EligibilityDetailsState } from "./state";

export interface GetEligibilityDetails {
  onSuccess?: () => void;
}

export type SaveEligibilityRequest = ActionWithCallback<
  SaveEligibilityDetailsPayload,
  number
>;
export interface InitiateDMIWorkflow {
  onSuccess?: () => void;
}

const actionCreator = actionCreatorFactory();

const initializeEligibilityIdActions = actionCreator.async<
  GetStartedEligibilityRequest,
  InitializeEligibilityIdResponse,
  Error
>("ELIGIBILITY_INIT");

const eligibilityDetailsActions = actionCreator.async<
  GetEligibilityDetails,
  EligibilityDetailsState,
  Error
>("ELIGIBILITY_DETAILS");

const saveEligibilityDetailsActions = actionCreator.async<
  SaveEligibilityRequest,
  EligibilityDetailsState,
  Error
>("SAVE_ELIGIBILITY_DETAILS");

const getIndustriesActions = actionCreator.async<void, string[], Error>(
  "GET_INDUSTRIES"
);
const getSubIndustriesActions = actionCreator.async<string, string[], Error>(
  "GET_SUB_INDUSTRIES"
);
const getCustomerListActions = actionCreator.async<void, Customer[], Error>(
  "CUSTOMER_LIST"
);

const changeLoanCalculationTypeAction = actionCreator<LoanCalculationType>(
  "CHANGE_LOAN_CALCULATION_TYPE"
);

const startLoanCalculationActions = actionCreator.async<
GetStartedLoanCalculation,
ResponseWithStatusCode|ResponseWithMessage,
Error
>("START_LOAN_CALCULATION");

const fetchLoanCalculationActions  = actionCreator.async<
EligibilityFetchActionObject,
LoanCalculationResponse,
Error
>("FETCH_LOAN_CALCULATION");

export const eligibilityActions = {
  initializeEligibilityId: initializeEligibilityIdActions,
  eligibilityDetails: eligibilityDetailsActions,
  saveEligibilityDetails: saveEligibilityDetailsActions,
  changeLoanCalculationType: changeLoanCalculationTypeAction,
  getIndustries: getIndustriesActions,
  getSubIndustries: getSubIndustriesActions,
  getCustomerList: getCustomerListActions,
  startLoanCalculation: startLoanCalculationActions,
  fetchLoanCalculation: fetchLoanCalculationActions
};
