import { reducerWithInitialState } from "typescript-fsa-reducers";
import { billActions } from "./actions";
import { BillState } from "./state";

export const initialState: BillState = {
  message: "",
  success: false,
  loading: false
};

export const UploadBillReducer = reducerWithInitialState(initialState)
  .case(billActions.upload.started, state => ({
    ...state,
    loading: true
  }))
  .case(billActions.upload.done, (state, response) => ({
    ...state,
    ...response.result,
    success: true,
    loading: false,
    error: undefined
  }))
  .case(billActions.upload.failed, (state, errorMessage) => ({
    ...state,
    loading: false,
    error: errorMessage
  }));
