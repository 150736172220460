import { reducerWithInitialState } from "typescript-fsa-reducers";
import { registrationActions } from "./actions";
import { RegistrationState } from "./state";

export const initialState: RegistrationState = {
  message: "",
  loading: false
};

export const RegistrationReducer = reducerWithInitialState(initialState)
  .case(registrationActions.register.started, state => ({
    ...state,
    loading: true
  }))
  .case(registrationActions.register.done, (state, response) => ({
    ...state,
    ...response.result,
    loading: false,
    error: undefined
  }))
  .case(registrationActions.register.failed, (state, errorMessage) => ({
    ...state,
    loading: false,
    error: errorMessage
  }));
