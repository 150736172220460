import { reducerWithInitialState } from "typescript-fsa-reducers";
import { EligibilityState } from "./state";
import { eligibilityActions } from "./actions";
import { LoanCalculationStatus } from "services/eligibilityService/EligibilityService.type";

export const initialState: EligibilityState = {
  loading: false,
  error: undefined,
  submitting: false,
  industries: { data: [] },
  subIndustries: { data: [] },
  customerList: { data: [] },
  loanDetails:{data:[],loading:false,calculating:false,calculated:false}
};

export const EligibilityReducer = reducerWithInitialState(initialState)
  .case(eligibilityActions.eligibilityDetails.started, state => ({
    ...state,
    loading: true,
    error: undefined
  }))
  .case(eligibilityActions.eligibilityDetails.done, (state, payload) => ({
    ...state,
    loading: false,
    error: undefined,
    eligibilityDetails: payload.result
  }))
  .case(eligibilityActions.eligibilityDetails.failed, (state, payload) => ({
    ...state,
    loading: false,
    error: payload.error
  }))
  .case(eligibilityActions.saveEligibilityDetails.started, state => ({
    ...state,
    submitting: true,
    error: undefined
  }))
  .case(eligibilityActions.saveEligibilityDetails.done, (state, payload) => ({
    ...state,
    submitting: false,
    eligibilityDetails: payload.result
  }))
  .case(eligibilityActions.saveEligibilityDetails.failed, (state, payload) => ({
    ...state,
    submitting: false,
    error: payload.error
  }))
  .case(eligibilityActions.changeLoanCalculationType, (state, payload) => ({
    ...state,
    eligibilityDetails: state.eligibilityDetails
      ? {
          ...state.eligibilityDetails,
          eligibilityAmountDetails: {
            ...state.eligibilityDetails.eligibilityAmountDetails,
            loanCalculationType: payload
          }
        }
      : undefined
  }))
  .case(eligibilityActions.getIndustries.started, state => ({
    ...state,
    industries: { ...state.industries, loading: true }
  }))
  .case(eligibilityActions.getIndustries.done, (state, payload) => ({
    ...state,
    industries: { data: payload.result, loading: false }
  }))
  .case(eligibilityActions.getIndustries.failed, state => ({
    ...state,
    industries: { data: [], loading: false }
  }))
  .case(eligibilityActions.getSubIndustries.started, state => ({
    ...state,
    subIndustries: { ...state.subIndustries, loading: true, data: [] }
  }))
  .case(eligibilityActions.getSubIndustries.failed, state => ({
    ...state,
    subIndustries: { data: [], loading: false }
  }))
  .case(eligibilityActions.getSubIndustries.done, (state, payload) => ({
    ...state,
    subIndustries: { data: payload.result, loading: false }
  }))
  .case(eligibilityActions.getCustomerList.started, state => ({
    ...state,
    customerList: { data: [], loading: true }
  }))
  .case(eligibilityActions.getCustomerList.failed, state => ({
    ...state,
    customerList: { data: [], loading: false }
  }))
  .case(eligibilityActions.getCustomerList.done, (state, payload) => ({
    ...state,
    customerList: { data: payload.result, loading: false }
  }))
  /**
   * LOANS
   * 
   * @TODO change to new section?
   * 
   */
  .case(eligibilityActions.startLoanCalculation.started, state => ({
    ...state,
    loanDetails: { ...state.loanDetails, loading: true, calculated: false, calculating:false }
  }))
  .case(eligibilityActions.startLoanCalculation.failed, state => ({
    ...state,
    loanDetails: { ...state.loanDetails,loading: false, calculated: false, calculating:false }
  }))
  .case(eligibilityActions.startLoanCalculation.done, (state, payload) => ({
    ...state,
    loanDetails: {...state.loanDetails,loading: false, calculated: false, calculating:((payload.result as any)?.message?.indexOf('STARTED') || (payload.result as any)?.data?.message?.indexOf('STARTED')) > -1 ? true : false }
  }))
  .case(eligibilityActions.fetchLoanCalculation.started, state => ({
    ...state,
    loanDetails: { ...state.loanDetails,loading: true }
  }))
  .case(eligibilityActions.fetchLoanCalculation.failed, state => ({
    ...state,
    loanDetails: { ...state.loanDetails,loading: false }
  }))
  .case(eligibilityActions.fetchLoanCalculation.done, (state, payload) => ({
    ...state,
    loanDetails: payload.result.status === LoanCalculationStatus.COMPLETED ?
      {data: [...state.loanDetails.data, payload.result], loading: false, calculated: true, calculating:false }
      : payload.result.status === LoanCalculationStatus.FAILED ?
        {...state.loanDetails, loading:false, calculating: false}
        : payload.result.status === LoanCalculationStatus.IN_PROGRESS && !state.loanDetails.calculating ?
          {...state.loanDetails, loading:false, calculating:true}  
          : {...state.loanDetails, loading:false}
  }));
