import actionCreatorFactory from "typescript-fsa";
import {
  RegistrationRequestPayload,
  RegistrationResponsePayload
} from "../../services/registrationService/RegistrationService.type";

export interface RegistrationRequest {
  data: RegistrationRequestPayload;
  redirect: () => void;
}

const actionCreator = actionCreatorFactory();

export const RegistrationActions = actionCreator.async<
  RegistrationRequest,
  RegistrationResponsePayload,
  Error
>("REGISTER");

export const registrationActions = {
  register: RegistrationActions
};
