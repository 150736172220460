import * as axios from "axios";

import { message } from "antd";

import { ServiceType } from "./ServiceType.data";
import { QueryPath } from "./QueryPath.data";
import { HttpContentType } from "./HttpContentType";
import AxiosInstance from "./AxiosInstance";
import { AppSettings } from "../AppSettings";
import SessionStorageKeys from "../constants/SessionStorageConstants";
import sessionStorage from "./SessionStorage";
import { API_ERROR_FEEDBACK } from "../Constants";
import { store } from "../store/Store";
import { applicationActions } from "../store/application/actions";
import { throttle } from "../helper/Throttle";

export interface ParsedError {
  message: string;
  result?: any;
}

const throttledError = throttle(message.error, 500);

/**
 * We need this Base class just to separate all http methods from core helper functions.
 * If we use mock API in future all this logic can be reused.
 */
export default abstract class ApiServiceBase {
  protected readonly serviceType: ServiceType;
  protected readonly hideFeedback: boolean;

  constructor(serviceType: ServiceType, hideFeedback?: boolean) {
    this.serviceType = serviceType;
    this.hideFeedback = !!hideFeedback;
  }

  public abstract get<T = void>(
    path: QueryPath,
    isBlob?: boolean
  ): Promise<T> | T;
  public abstract post<T = void>(path: QueryPath, body: any): Promise<T> | T;
  public abstract patch<T = void>(path: QueryPath, body: any): Promise<T> | T;
  public abstract put<T = void>(path: QueryPath, body: any): Promise<T> | T;
  public abstract delete<T = void>(path: QueryPath): Promise<T> | T;
  public abstract postMultipart<T = void>(
    path: QueryPath,
    data: any
  ): Promise<T> | T;

  public provideErrorFeedback(content: string) {
    if (!this.hideFeedback) {
      throttledError(content);
    }
  }

  // tslint:disable-next-line: cyclomatic-complexity
  public processError(error: any): ParsedError {
    const errorCode = error.response ? error.response.status || 500 : 500;
    switch (errorCode) {
      case 401:
        store.dispatch(applicationActions.flushData());
        sessionStorage.removeItem(SessionStorageKeys.AuthToken);
        this.provideErrorFeedback(API_ERROR_FEEDBACK.sessionExpired);
        return { message: "Unauthorized" };
      case 404:
        this.provideErrorFeedback(API_ERROR_FEEDBACK.error);
        return { message: "The request is not found" };
      case 500:
        this.provideErrorFeedback(API_ERROR_FEEDBACK.error);
        return { message: "Internal server error" };
      case 400:
      case 403:
      case 412:
      case 422:
      case 423:
      case 424: {
        const err = error.response.data;

        if (err) {
          if (err.message && err.message.isJson) {
            //if "isJson" flag is set, returning the error object, stringified
            delete err.message.isJson;
            return new Error(JSON.stringify(err.message, null, 4));
          } else if (err.message) {
            //showing feedback to user about the error if message is string
            let errMsg;
            try {
              errMsg =
                typeof err.message === "string"
                  ? err.message
                  : typeof err.message === "object"
                  ? err.message[0]
                  : API_ERROR_FEEDBACK.error;
            } catch (tError) {
              errMsg = API_ERROR_FEEDBACK.error;
            }
            this.provideErrorFeedback(errMsg);
            //Just to be safe, prevent runtime error if it is not string.
            return {
              message:
                typeof err.message === "string"
                  ? err.message
                  : JSON.stringify(err.message),
              result: err.result || ""
            };
          }
        }
        return { message: "Error" };
      }
    }
    return error;
  }

  //It returns Headers to make API call
  protected getConfig(contentType: HttpContentType): axios.AxiosRequestConfig {
    const token = sessionStorage.getItem(SessionStorageKeys.AuthToken);
    const headers = {
      "Content-Type": contentType.toString(),
      Authorization: token && `Token ${token}`
    };
    return { headers };
  }

  //Returns an axios instance
  protected getAxiosInstance(): axios.AxiosInstance {
    const instance = AxiosInstance.create();
    return instance;
  }

  // Generates url: {AppSettings.service.baseUrl}/{this.serviceType}/{routeParam1}/{routeParam2}/.../{routeParamN}?{queryParam1key}={queryParam1val}&{queryParam2key}={queryParam2val}...
  // Need this to be able to write the mocks properly. Don't want to parse urls.
  // Query params with null, undefined or empty string won't be appended to the url.

  protected getUrl(path: QueryPath): string {
    const baseUrl = AppSettings.server.baseUrl;
    let url: string = `${baseUrl}/${this.serviceType}`;

    if (path) {
      if (path.route && path.route.length > 0) {
        for (const route of path.route) {
          if (route && route !== "undefined") {
            url += `/${route}`;
          }
        }
      }
      if (path.query) {
        let separator = "?";
        for (const name in path.query) {
          if (path.query[name]) {
            url += `${separator}${encodeURI(name)}=${encodeURIComponent(
              path.query[name]!.toString()
            )}`;
            separator = "&";
          }
        }
      }
    }
    return url;
  }
}
