import { takeEvery, put, call } from "redux-saga/effects";
import { Action } from "typescript-fsa";

import { authActions, LoginIssueRequestData } from "./actions";
import authService from "../../services/authService/AuthService";
import sessionStorage from "../../services/SessionStorage";
import {
  LoginRequestPayload,
  LoginResponse,
  EligibilityMetaDataResponse,
  LoginIssueResponse
} from "../../services/authService/AuthService.type";
import SessionStorageKeys from "constants/SessionStorageConstants";
import { EligibilityService } from "../../services/eligibilityService";
// import { getEligibilityRoute } from "../../hooks/eligibility/useEligibilityRouting";
import { RedirectSelector } from "../../helperHooks/useRedirect";
// import { LoanCalculationResponse } from "services/eligibilityService/EligibilityService.type";
// import { eligibilityActions } from "store/eligibility/actions";

function* LoginWorker(action: Action<LoginRequestPayload>) {
  const {
    payload,
    payload: { data, redirectTo }
  } = action;
  try {
    const response: LoginResponse = yield call(authService.login, data);
    const {
      token,
      gstin,
      gstUser: { name, s3Key }
    } = response;
    sessionStorage.setItem(SessionStorageKeys.AuthToken, token);
    const eligibilityMetaDataResponse: EligibilityMetaDataResponse = yield call(
      EligibilityService.eligibilityMetaData,
    );

    // const { status } = eligibilityMetaDataResponse;
    // const loanCalculationData:LoanCalculationResponse = yield EligibilityService.fetchLoanCalculation(eligibilityId) as LoanCalculationResponse;
    // yield put(eligibilityActions.fetchLoanCalculation.done({result:loanCalculationData,params:{eligibilityId,silent:true}}))
    const prevGstin = sessionStorage.getItem(SessionStorageKeys.GSTIN);
    sessionStorage.setItem(SessionStorageKeys.Username, name);
    sessionStorage.setItem(SessionStorageKeys.GSTIN, gstin);
    if (s3Key) {
      sessionStorage.setItem(SessionStorageKeys.ProfileImageKey, s3Key);
    }

    if (redirectTo) {
      /* AC - make home page the default route pon logging in */
      // const [route] = getEligibilityRoute(status);
      const route = '/home';
      yield redirectTo(route, prevGstin === gstin);
    }

    yield put(
      authActions.getEligibilityMetaData.done({
        params: undefined,
        result: { ...eligibilityMetaDataResponse }
      })
    );
    yield put(
      authActions.login.done({
        params: payload,
        result: { ...response }
      })
    );
  } catch (error) {
    yield put(authActions.login.failed(error.message));
  }
}

function* EligibilityMetaDataWorker(
  action: Action<RedirectSelector | undefined>
) {
  try {
    const response: EligibilityMetaDataResponse = yield EligibilityService.eligibilityMetaData();

    if (action.payload && !response.status) {
      //action.payload is redirect function
      action.payload(route => route.home.initializeEligibility);
    }

    yield put(
      authActions.getEligibilityMetaData.done({
        result: response,
        params: action.payload
      })
    );
  } catch (error) {
    yield put(
      authActions.getEligibilityMetaData.failed(error.message || error)
    );
  }
}

function* LoginIssueWorker(action: Action<LoginIssueRequestData>) {
  try {
    const { data, onSuccess } = action.payload;
    const response: LoginIssueResponse = yield authService.loginIssue(data);
    yield put(
      authActions.reportLoginIssue.done({
        result: response,
        params: action.payload
      })
    );
    if (onSuccess) {
      onSuccess(response.message);
    }
  } catch (error) {
    yield put(authActions.reportLoginIssue.failed(error.message || error));
  }
}

function* AuthWatcher(): IterableIterator<any> {
  yield takeEvery(authActions.login.started, LoginWorker);
  yield takeEvery(authActions.reportLoginIssue.started, LoginIssueWorker);
  yield takeEvery(
    authActions.getEligibilityMetaData.started,
    EligibilityMetaDataWorker
  );
}

export default AuthWatcher;
