import { UserConsentObject } from "services/profileService/ProfileService.type";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { profileActions } from "./actions";
import { ProfileState } from "./state";

export const initialState: ProfileState = {
  message: "",
  loading: false,
  userConsent: {} as UserConsentObject,
};

export const ProfileReducer = reducerWithInitialState(initialState)
  .case(profileActions.updateProfile.started, state => ({
    ...state,
    loading: true
  }))
  .case(profileActions.updateProfile.done, (state, response) => ({
    ...state,
    message: response.result.message,
    loading: false,
    error: undefined
  }))
  .case(profileActions.updateProfile.failed, (state, errorMessage) => ({
    ...state,
    loading: false,
    error: errorMessage
  }))
  .case(profileActions.setBorrowerConsent.started, (state, errorMessage) => ({
    ...state,
    loading: true,
    error: errorMessage
  }))
  .case(profileActions.setBorrowerConsent.failed, (state, errorMessage) => ({
    ...state,
    loading: false,
    error: errorMessage
  }))
  .case(profileActions.setBorrowerConsent.done, (state, payload) => ({
    ...state,
    userConsent: payload.result,
  }));
