import { all } from "redux-saga/effects";
import AuthWatcher from "./store/auth/sagas";
import RegistrationWatcher from "./store/registration/sagas";
import EligibilityWatcher from "./store/eligibility/sagas";
import BillWatcher from "./store/bill/sagas";
import ProfileWatcher from "./store/profile/sagas";

export default function* sagas() {
  yield all([
    // add sagas/watchers
    EligibilityWatcher(),
    BillWatcher(),
    AuthWatcher(),
    RegistrationWatcher(),
    ProfileWatcher()
  ]);
}
